<template>

  <b-col
    cols="12"
    :lg="'12'"
    class="p-0"
  >
    <b-card
      v-if="summary"
      class="w-30 p-0 card-info dashboard"
    >
      <b-button
        v-if="$can('update', 'users') && resellerId"
        class="mx-1"
        :to="`/user-management/resellers/update/${itemId}`"
        variant="secondary"
      >
        Edit profile
      </b-button>
      <b-button
        v-if="$can('create', 'sites') && clientId"
        class="mx-1"
        :to="`/sites/create`"
        variant="secondary"
      >
        Create site
      </b-button>
      <b-row>
        <b-col
          v-for="(item) in summary"
          :key="item.code"
          :class="'my-1'"
          cols="12"
          :md="simrushId ? '4' : '6'"
        >
          <b-col
            v-if="item.code !== 'simrush'"
            class="border text-light rounded p-0 d-flex flex-column justify-content-between"
            cols="12"
          >
            <div class="position-relative">
              <h3 class="text-capitalize dashboard-card-title">
                {{ item.description }}
              </h3>
              <h4
                class="text-capitalize mb-2"
                style="min-height:25px"
              >
                <div v-if="item.code !== 'hardware' && item.code !== 'licence' && item.code !== 'invites'">
                  <span class="d-block">Total: {{ item.count }}</span>
                  <span>&nbsp;</span>
                </div>
                <div v-else-if="item.code === 'invites'">
                  <span
                    class="d-block"
                  > Pending: {{ item.pending }}</span>
                  <span
                    style="font-size:17px;"
                  > Expired: {{ item.expired }}</span>
                </div>
                <div v-else>
                  <span
                    class="d-block"
                  > Total: {{ item.count }}</span>
                  <span
                    style="font-size:17px;"
                  > Allocated: {{ item.assigned }}</span>
                  <span
                    class="d-block"
                  > Unallocated: {{ item.unassigned }}</span>
                </div>
              </h4>
              <img
                :src="getItemIcon(item.code)"
                size="75"
                class="position-absolute align-middle mr-25 type-icon dashboard-card-image"
                alt=""
              >
              <b-row class="wrapper-search-more-link">
                <b-col v-if="item.code !== 'invites'">
                  <Select
                    :type="item.code"
                    :item-id="itemId || 0"
                    :item-type-id="item.type_id || 0"
                    :is-summary="true"
                    :client-id="clientId"
                    :reseller-id="resellerId"
                    :distributor-id="distributorId"
                    :master-distributor-id="masterDistributorId"
                    :simrush-id="simrushId"
                  />
                </b-col>
                <b-col class="text-right">
                  <b-link
                    :href="getRedirectLink(item.code, itemId || 0, item.type_id || 0)"
                    class="card-link more-info"
                  > More info
                    <img
                      :src="require('@/assets/images/icons/dashboard/chevron.svg')"
                      alt=""
                    >
                  </b-link>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-col>
      </b-row>
    </b-card>
  </b-col>
</template>

<script>
/* eslint-disable global-require */
import {
  BCard, BRow, BCol, BButton, BLink,
} from 'bootstrap-vue'
import Select from './SearchSelectDashboard.vue'

export default {
  components: {
    BCard, BRow, BCol, Select, BButton, BLink,
  },
  props: {
    summary: {
      required: true,
      type: Object,
    },
    itemId: {
      required: true,
      type: Number,
    },
    clientId: {
      default: null,
      type: String,
    },
    resellerId: {
      default: null,
      type: String,
    },
    distributorId: {
      default: null,
      type: String,
    },
    masterDistributorId: {
      default: null,
      type: String,
    },
    simrushId: {
      default: null,
      type: String,
    },
  },
  methods: {
    getItemIcon(name) {
      let icon
      switch (name) {
        case 'master-distributor':
          icon = require('@/assets/images/icons/dashboard/master-distributer.svg')
          break
        case 'distributor':
          icon = require('@/assets/images/icons/dashboard/distributor.svg')
          break
        case 'reseller':
          icon = require('@/assets/images/icons/dashboard/reseller.svg')
          break
        case 'client':
          icon = require('@/assets/images/icons/dashboard/client.svg')
          break
        case 'site':
          icon = require('@/assets/images/icons/dashboard/site.svg')
          break
        case 'user':
        case 'invites':
          icon = require('@/assets/images/icons/dashboard/user.svg')
          break
        default:
          icon = require('@/assets/images/icons/dashboard/hardware.svg')
      }
      return icon
    },
    getRedirectLink(code, companyId, companyTypeId) {
      if (code === 'licence' || code === 'hardware') {
        return `/${code}/list`.concat((companyId ? `?companyId=${companyId}` : ''))
      }
      if (code === 'invites') {
        return '/invite-user/list'.concat((companyId ? `?companyId=${companyId}` : ''))
      }
      if (code === 'site') {
        return '/sites/list'.concat((companyId ? `?companyId=${companyId}` : ''))
      }
      if (code === 'user') {
        return '/user-management/list'.concat((companyId ? `?companyId=${companyId}` : ''))
      }
      return `/company-management/list?pageNum=1&perPage=25&searchFields=type.id%3Alike%3Bis_active&search=type.id%3A${companyTypeId}%3Bis_active%3A1&searchJoin=and`.concat((companyId ? `&companyId=${companyId}` : ''))
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
