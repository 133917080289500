<template>
  <div>
    <b-spinner
      v-if="loading"
      variant="primary"
      label="Spinning"
      class="position-absolute"
      style="top:50%; left:48%;"
    />
    <b-card v-else>
      <div class="company-info-card-container">
        <div
          class="site-info-card"
          :class="{ 'expanded': expandedCard.info }"
        >
          <h2>
            Company Information
          </h2>
          <table class="mt-2 mt-xl-0 w-100">
            <tr v-if="item.parent && item.parent.type && item.parent.type !== 'simrush'">
              <th class="pb-50 pr-50">
                <feather-icon
                  icon="ArrowRightCircleIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Parent company:</span>
              </th>
              <td class="pb-50">
                <a :href="`/company-management/summary/${item.parent.id}`">
                  {{ item.parent.name }}
                </a>
              </td>
            </tr>
            <tr>
              <th class="pb-50 pr-50">
                <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Name:</span>
              </th>
              <td class="pb-50">
                {{ item.name }}
              </td>
            </tr>
            <tr v-if="item.registered_number">
              <th class="pb-50 pr-50">
                <feather-icon
                  icon="FlagIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Registered Number:</span>
              </th>
              <td class="pb-50">
                {{ item.registered_number }}
              </td>
            </tr>
            <tr v-if="item.vat_id">
              <th class="pb-50 pr-50">
                <feather-icon
                  icon="HomeIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">VAT ID:</span>
              </th>
              <td class="pb-50">
                {{ item.vat_id }}
              </td>
            </tr>
            <tr v-if="item.billingTerm">
              <th class="pb-50 pr-50">
                <feather-icon
                  icon="ActivityIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Billing Terms:</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ item.billingTerm.name }}
              </td>
            </tr>
            <tr v-if="item.currency">
              <th class="pb-50 pr-50">
                <feather-icon
                  icon="DollarSignIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Currency:</span>
              </th>
              <td class="pb-50">
                {{ item.currency.name }}
              </td>
            </tr>
            <tr v-if="telephone">
              <th class="pb-50 pr-50">
                <feather-icon
                  icon="PhoneIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Telephone:</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ telephone }}
              </td>
            </tr>
            <tr>
              <th class="pb-50 pr-50">
                <feather-icon
                  icon="MailIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Email</span>
              </th>
              <td class="pb-50">
                {{ item.email }}
              </td>
            </tr>
            <tr v-if="item.web">
              <th class="pb-50 pr-50">
                <feather-icon
                  icon="LinkIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Web:</span>
              </th>
              <td class="pb-50">
                {{ item.web }}
              </td>
            </tr>
            <tr>
              <th>
                <feather-icon
                  icon="TypeIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Type:</span>
              </th>
              <td>
                {{ item.type.name }}
              </td>
            </tr>
          </table>
          <button
            class="site-info-card__expand"
            :class="{ 'expanded': expandedCard.info }"
            @click="expand('info')"
          >
            <feather-icon icon="ChevronDownIcon" />
          </button>
        </div>
        <div
          class="site-info-card long-expand"
          :class="{ 'expanded': expandedCard.address }"
        >
          <h2>
            Company Address
          </h2>
          <h6>Shipping Address</h6>
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50 pr-50">
                <feather-icon
                  icon="MapPinIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Country:</span>
              </th>
              <td class="pb-50">
                {{ item.shipping.country.name }}
              </td>
            </tr>
            <tr v-if="item.shipping.state">
              <th class="pb-50 pr-50">
                <feather-icon
                  icon="MapIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">State:</span>
              </th>
              <td class="pb-50">
                {{ item.shipping.state }}
              </td>
            </tr>
            <tr>
              <th class="pb-50 pr-50">
                <feather-icon
                  icon="MapIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">City:</span>
              </th>
              <td class="pb-50">
                {{ item.shipping.city }}
              </td>
            </tr>
            <tr v-if="item.shipping.address1">
              <th class="pb-50 pr-50">
                <feather-icon
                  icon="MapIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Address 1:</span>
              </th>
              <td class="pb-50">
                {{ item.shipping.address1 }}
              </td>
            </tr>
            <tr v-if="item.shipping.address2">
              <th class="pb-50 pr-50">
                <feather-icon
                  icon="MapIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Address 2:</span>
              </th>
              <td class="pb-50">
                {{ item.shipping.address2 }}
              </td>
            </tr>
            <tr v-if="item.shipping.postal_code">
              <th class="pb-50 pr-50">
                <feather-icon
                  icon="MapIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Postal Code:</span>
              </th>
              <td class="pb-50">
                {{ item.shipping.postcode }}
              </td>
            </tr>
          </table>
          <h6 class="mt-2">
            Billing Address
          </h6>
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50 pr-50">
                <feather-icon
                  icon="MapPinIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Country:</span>
              </th>
              <td class="pb-50">
                {{ item.billing.country.name }}
              </td>
            </tr>
            <tr v-if="item.billing.state">
              <th class="pb-50 pr-50">
                <feather-icon
                  icon="MapIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">State:</span>
              </th>
              <td class="pb-50">
                {{ item.billing.state }}
              </td>
            </tr>
            <tr>
              <th class="pb-50 pr-50">
                <feather-icon
                  icon="MapIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">City:</span>
              </th>
              <td class="pb-50">
                {{ item.billing.city }}
              </td>
            </tr>
            <tr v-if="item.billing.address1">
              <th class="pb-50 pr-50">
                <feather-icon
                  icon="MapIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Address 1:</span>
              </th>
              <td class="pb-50">
                {{ item.billing.address1 }}
              </td>
            </tr>
            <tr v-if="item.billing.address2">
              <th class="pb-50 pr-50">
                <feather-icon
                  icon="MapIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Address 2:</span>
              </th>
              <td class="pb-50">
                {{ item.billing.address2 }}
              </td>
            </tr>
            <tr v-if="item.billing.postal_code">
              <th class="pb-50 pr-50">
                <feather-icon
                  icon="MapIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Postal Code:</span>
              </th>
              <td class="pb-50">
                {{ item.billing.postcode }}
              </td>
            </tr>
          </table>
          <button
            class="site-info-card__expand"
            :class="{ 'expanded': expandedCard.address }"
            @click="expand('address')"
          >
            <feather-icon icon="ChevronDownIcon" />
          </button>
        </div>
        <div
          class="site-info-card"
          :class="{ 'expanded': expandedCard.invite }"
        >
          <h2>Invite user</h2>
          <!-- form -->
          <validation-observer ref="userInvite">
            <b-form>
              <b-form-group
                label="User Email"
                label-for="email"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  vid="email"
                  rules="required|email|max:255"
                >
                  <b-form-input
                    v-model="form.email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Email"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                v-if="userRoles.length > 0"
                label="User Role"
                label-for="user-type"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="user-type"
                  vid="user_role_id"
                  rules="required"
                >

                  <v-select
                    v-model="form.user_role_id"
                    :options="userRoles"
                    :reduce="user_role_id => user_role_id.id"
                    placeholder="Select user role"
                    label="name"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  :disabled="loading"
                  @click.prevent="validationForm"
                >Submit</b-button>
              </b-form-group>
            </b-form>
          </validation-observer>
          <button
            class="site-info-card__expand"
            :class="{ 'expanded': expandedCard.invite }"
            @click="expand('invite')"
          >
            <feather-icon icon="ChevronDownIcon" />
          </button>
        </div>
      </div>
    </b-card>
    <CardsList
      v-if="companyStatistics"
      :summary="companyStatistics"
      :item-id="id"
    />
  </div>
</template>

<script>
import {
  BCard, BSpinner, BFormGroup, BFormInput, BForm, BButton,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CardsList from '@/components/CardsList.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { mapFields } from 'vuex-map-fields'
import { required, email, max } from '@validations'
import parsePhoneNumber from 'libphonenumber-js'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    vSelect,
    BForm,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    CardsList,
    BCard,
    BSpinner,
  },
  data() {
    return {
      loading: false,
      id: 0,
      form: {
        email: '',
        user_role_id: null,
      },
      required,
      emailRule: email,
      max,
      expandedCard: {
        info: false,
        invite: false,
        address: false,
      },
    }
  },
  computed: {
    ...mapState('companyManagement', {
      countries: 'countries',
      item: 'item',
      companyStatistics: 'companyStatistics',
      defaultIso: 'defaultIso',
    }),
    ...mapFields('invite', [
      'userRoles',
    ]),
    telephone() {
      if (this.item.phone_country_iso) {
        const phoneNumber = parsePhoneNumber(this.item.phone_number, this.item.phone_country_iso)
        return phoneNumber.number
      }
      return `${this.item.phone_number}`
    },
  },
  async created() {
    const { id } = this.$route.params
    this.id = id
    this.loading = true
    try {
      await this.fetchUserRoles()

      if (id) {
        await this.getItem(id)
        await this.getCompanyStatistics(id)
      } else {
        this.$router.push({ name: 'error-404' })
      }
    } catch (error) {
      const { status } = error.response
      if (status === 404) {
        this.$router.push({ name: 'error-404' })
      }
      if (!status) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Server error',
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      }
    }
    this.loading = false
  },
  methods: {
    ...mapActions('companyManagement', [
      'getItem',
      'getCompanyStatistics',
    ]),
    ...mapActions('invite', [
      'fetchUserRoles',
    ]),
    expand(card) {
      this.expandedCard[card] = !this.expandedCard[card]
    },
    async validationForm() {
      const valid = await this.$refs.userInvite.validate()
      if (valid) {
        try {
          const payload = { ...this.form }
          payload.company_id = this.id
          payload.is_new_company = 0

          await this.$http.post('/user/invite/store', {
            ...payload,
          })

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success!',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        } catch (error) {
          const { status } = error.response

          if (!status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }
          if (status === 422) {
            const { fields } = error.response.data

            this.$refs.userInvite.setErrors(fields)
          }
        }
      }
    },
  },
}
</script>

<style>

</style>
