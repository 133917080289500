<template>
  <div>
    <b-form>
      <b-row>
        <b-col
          sm="12"
          class="mb-1"
        >
          <feather-icon
            v-if="!showSelect"
            icon="SearchIcon"
            size="24"
            class="mb-1 select-icon"
            @click="handleSearchClick"
          />
          <div style="position:relative">
            <v-select
              v-show="showSelect"
              ref="select"
              v-model="selectedItem"
              :options="optionsSummaryList"
              placeholder="Enter 3 or more characters"
              :label="'name'"
              :no-drop="!showDropdown"
              :loading="loading"
              @search=" startLoading($event), isSummary ? onSearchSummary($event, type, itemId, itemTypeId) : onSearch($event, type)"
              @input="getItemId($event)"
              @search:blur="showSelect = false"
            >
              <template #spinner="{ loading }">
                <b-spinner
                  v-if="loading"
                  variant="primary"
                  label="Spinning"
                  class="position-absolute"
                  style="top:50%; left:48%;"
                />
              </template>
              <span
                slot="no-options"
                class="no-option-select"
                @click="$refs.select.open = false"
              >
                Sorry, no matching options
              </span>
            </v-select>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import axios from '@axios'
import { required, max, min } from '@validations'
import { debounce } from '@/utils/utils'

import {
  BForm, BRow, BCol, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
  directives: {
    Ripple,
  },
  components: {
    vSelect, BForm, BRow, BCol, BSpinner,
  },
  props: {
    type: {
      required: true,
      default: null,
      type: String,
    },
    itemId: {
      required: true,
      default: null,
      type: Number,
    },
    itemTypeId: {
      required: true,
      default: null,
      type: Number,
    },
    isSummary: {
      type: Boolean,
    },
    clientId: {
      default: null,
      type: String,
    },
    resellerId: {
      default: null,
      type: String,
    },
    distributorId: {
      default: null,
      type: String,
    },
    masterDistributorId: {
      default: null,
      type: String,
    },
    simrushId: {
      default: null,
      type: String,
    },
  },
  data() {
    return {
      required,
      selectedItem: null,
      max,
      min,
      selectedId: null,
      showSelect: false,
      loading: false,
      showDropdown: false,
    }
  },
  computed: {
    ...mapFields('dashboard', {
      optionsSummaryList: 'optionsSummaryList',
    }),
  },
  methods: {
    ...mapActions('dashboard', {
      fetchParentCompany: 'fetchParentCompanyList',
      fetchSite: 'fetchSiteList',
      fetchLicence: 'fetchLicenceList',
      fetchHardware: 'fetchHardwareList',
      fetchUser: 'fetchUserList',
    }),
    handleSearchClick() {
      this.showSelect = !this.showSelect
      this.$nextTick(() => {
        this.$refs.select.$refs.search.focus()
      })
    },
    startLoading(query) {
      this.showDropdown = false
      if (query.length < 3) {
        this.optionsSummaryList = []
        return
      }
      this.showDropdown = true
      this.loading = true
    },
    // eslint-disable-next-line func-names
    onSearch: debounce(async function (query, type) {
      if (query.length < 3) {
        this.loading = false
        this.optionsSummaryList = []
        return
      }
      if (type === 'master-distributor') {
        const { data } = await axios.get(`/dashboard/search/master-distributor?q=${query}`)
        this.optionsSummaryList = [...data.data]
      }
      if (type === 'distributor') {
        const { data } = await axios.get(`/dashboard/search/distributor?q=${query}`)
        this.optionsSummaryList = [...data.data]
      }
      if (type === 'reseller') {
        const { data } = await axios.get(`/dashboard/search/reseller?q=${query}`)
        this.optionsSummaryList = [...data.data]
      }
      if (type === 'client') {
        const { data } = await axios.get(`/dashboard/search/client?q=${query}`)
        this.optionsSummaryList = [...data.data]
      }
      if (type === 'site') {
        const { data } = await axios.get(`/dashboard/search/site?q=${query}`)
        this.optionsSummaryList = [...data.data]
      }
      this.loading = false
    }, 350),
    // eslint-disable-next-line func-names
    onSearchSummary: debounce(async function (query, type, id, itemTypeId) {
      if (query.length < 3) {
        this.optionsSummaryList = []
        this.loading = false
        return
      }
      if (type === 'master-distributor' || type === 'distributor' || type === 'reseller' || type === 'client') {
        this.optionsSummaryList = await this.fetchParentCompany({ query, id, typeId: itemTypeId })
      }
      if (type === 'site') {
        this.optionsSummaryList = await this.fetchSite({ query, companyId: id })
      }
      if (type === 'licence') {
        this.optionsSummaryList = await this.fetchLicence({ query, companyId: id })
      }
      if (type === 'hardware') {
        this.optionsSummaryList = await this.fetchHardware({ query, companyId: id })
      }
      if (type === 'user') {
        this.optionsSummaryList = await this.fetchUser({ query, companyId: id })
      }
      this.loading = false
    }, 350),
    getItemId(item) {
      if (item) {
        this.selectedId = item.id.toString()
      } else {
        this.optionsSummaryList = []
        this.selectedId = null
      }
      this.redirectToOption()
    },
    redirectToOption() {
      if (!this.selectedId) {
        return
      }
      if (this.type === 'master-distributor' || this.type === 'distributor' || this.type === 'reseller' || this.type === 'client') {
        this.$router.push(`/company-management/summary/${this.selectedId}`)
      } else if (this.type === 'site') {
        this.$router.push({
          name: 'site-summary',
          params: { id: this.selectedId },
        })
      } else if (this.type === 'hardware') {
        this.$router.push({
          name: 'hardware-update',
          params: { id: this.selectedId },
        })
      } else if (this.type === 'licence') {
        this.$router.push({
          name: 'licence-update',
          params: { id: this.selectedId },
        })
      } else if (this.type === 'user') {
        this.$router.push({
          name: 'user-management-update',
          params: { id: this.selectedId },
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.select-icon {
  cursor: pointer;
  color:#5e5873;
}
.dark-layout {
  .select-icon {
    color:#d0d2d6;
  }
}
</style>
